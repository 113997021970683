$spacing: 8px;

$baseFont: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
$titleFontSize: 22px;
$subTitleFontSize: 16px;
$bodyFontSize: 14px;
$labelFontSize: 11px;
$creditsFontSize: 19px;

$subTitleLineHeight: 1.375;

$bold: 700;

$pageBackgroundColor: #f2f2f7;
$mainBackgroundColor: #ffffff;
$blue: #007aff;
$azure: rgba(231, 241, 254, 1);
$azureDark: rgba(231, 241, 254, 1);
$grey: rgba(60, 60, 67, 0.6);
$red: #ff0000;