@import "./variables";

html {
  height: 100%;
  background-color: $pageBackgroundColor;
}

body {
  font-family: $baseFont;
  font-size: $bodyFontSize;
  height: 100%;
  margin: auto;

  #__next {
    height: 100%;
  }
}

a {
  color: $grey;
  text-decoration: underline;
}